import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Badge, Alert } from "react-bootstrap";
import { useQueryClient } from "react-query";

import Speed from "@mui/icons-material/Speed";
import CalendarToday from "@mui/icons-material/CalendarToday";
import ChatBubbleOutline from "@mui/icons-material/ChatBubbleOutline";
import PersonOutline from "@mui/icons-material/PersonOutline";
import Settings from "@mui/icons-material/Settings";
import Add from "@mui/icons-material/Add";
import InfoRounded from "@mui/icons-material/InfoRounded";
import AssignmentTurnedInOutlined from "@mui/icons-material/AssignmentTurnedInOutlined";

import {
  InternalPageContent,
  ContentNav,
  MobileNav,
  ContentBody,
} from "./components/Internal/Internal.elements";
import UserNotifications from "./components/Internal/UserNotifications";
import Mobile from "./components/Navs/Mobile";
import Footer from "./components/Footer";
import { useUser } from "../../contexts/UserContext";
import useOnboard from "../../hooks/useOnboard";
import { useApiGet } from "../../hooks/useApi";
import JobRequestStartModal from "../jobRequest/JobRequestStartModal";

import logo from "../../logo.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "./PrimaryLayout.css";

const navList = [
  {
    icon: <Speed />,
    url: "/dashboard",
    label: "Dashboard",
    types: ["sysadmin", "helper"],
    showBadge: false,
  },
  {
    icon: <CalendarToday />,
    url: "/calendar",
    label: "Calendar",
    types: [],
    showBadge: false,
  },
  {
    icon: <AssignmentTurnedInOutlined />,
    url: "/bookings",
    label: "Bookings",
    types: [],
    showBadge: true,
  },
  {
    icon: <ChatBubbleOutline />,
    url: "/messages",
    label: "Messages",
    types: [],
    showBadge: true,
  },
  /*{
    icon: <AttachMoney />,
    url: "/payments",
    label: "Payments",
    types: [],
    showBadge: false,
  },*/
  {
    icon: <Settings />,
    url: "/account",
    label: "Account",
    types: [],
    showBadge: false,
  },
  {
    icon: <PersonOutline />,
    url: "/profile",
    label: "Profile",
    types: ["helper"],
    showBadge: false,
  },
  {
    icon: <PersonOutline />,
    url: "/profile",
    label: "Profile",
    types: ["customer"],
    showBadge: false,
    addon: (
      <div className="float-end text-end">
        <Link to={"/profile/add"}>
          <Button variant="link" className="m-0 p-0">
            <Add />
          </Button>
        </Link>
      </div>
    ),
  },
];

const renderList = ({ notifications, type, list, isSubNav = false }) => {
  return list.map((item, i) => {
    if (item.types.length > 0 && !item.types.includes(type)) {
      return null;
    }

    const reg = new RegExp(`^\\${item.url}(?:\\?)?$`);
    const isActive = reg.test(window.location.pathname);
    const isDisabled = item.isDisabled || false;
    const classNames = item.className ? item.className.split(" ") : [];

    if (isActive) {
      classNames.push("active");
    } else if (isDisabled) {
      classNames.push("disabled");
    }

    if (
      notifications &&
      notifications[item.label.toLowerCase()] &&
      notifications[item.label.toLowerCase()] > 0
    ) {
      item.showBadge = true;
      item.badgeCount = notifications[item.label.toLowerCase()];
    } else {
      item.showBadge = false;
      item.badgeCount = 0;
    }

    // button  with a url should render as a button but work as a link
    if (item.btn && item.url) {
      return (
        <div key={i} className="btn-item">
          <Link to={item.url} className="btn btn-outline-primary">
            {item.label}
          </Link>
        </div>
      );
    }

    // button with onClick should render as a button and work as a button
    if (item.btn && item.onClick) {
      return (
        <div key={i} className="btn-item">
          <button
            type="button"
            className="btn btn-outline-primary w-100"
            onClick={() => item.onClick()}
          >
            {item.label}
          </button>
        </div>
      );
    }

    // normal links
    return (
      <div key={i} className="link-item">
        {item.addon ? item.addon : null}
        <Link to={item.url} className={classNames.join(" ")}>
          {!isSubNav ? (
            <span className="nav-icon">
              {item.icon ? (
                item.icon
              ) : (
                <img
                  src="https://img.spacergif.org/v1/spacer.gif"
                  alt=""
                  width="24"
                  height="1"
                ></img>
              )}
            </span>
          ) : (
            <span className="nav-icon" />
          )}
          <span className="nav-label">{item.label}</span>
          {item.showBadge && item?.badgeCount > 0 ? (
            <Badge bg="danger">{item?.badgeCount}</Badge>
          ) : null}
        </Link>
        {item.children && item.children.length > 0 ? (
          <div className="child-nav">
            {renderList({
              notifications,
              type,
              list: item.children,
              isSubNav: true,
            })}
          </div>
        ) : null}
      </div>
    );
  });
};

const InternalLayout = ({
  children,
  showNav = true,
  internalPageClass = "",
  noMaxWidth = false,
}) => {
  const { user, signOut } = useUser();
  const type = user.helper ? "helper" : "customer";
  const [nav, setNav] = useState([]);
  const [newBooking, setNewBooking] = useState(false);
  const { showOnBoarding, welcomeNav } = useOnboard();
  const queryClient = useQueryClient();

  const { data: notifications } = useApiGet(
    "nav-counts",
    "/users/notifications/counts"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    const newNav = [
      ...navList.filter(
        (item) => item.types.length === 0 || item.types.includes(user.userType)
      ),
    ];

    if (type === "customer" && user.customer && user.customer.profiles) {
      // add in the sub profiles for the user
      user.customer.profiles.forEach((p) => {
        newNav.push({
          icon: null,
          url: `/profile/${p._id}`,
          label: p.name,
          types: ["customer"],
          className: "sub-profile-link",
        });
      });

      // add in the new job request button for customers
      newNav.push({
        icon: null,
        url: null,
        label: "New Booking",
        types: ["customer"],
        btn: true,
        onClick: () => {
          setNewBooking(true);
          /*const requestedFor =
            user.customer?.profiles.length > 0
              ? user.customer.profiles[0]
              : null;
          resetJob({
            isNewCustomer: false,
            serviceArea: { _id: user.customer.serviceArea },
            zip: user.address.postal,
            requestedFor: requestedFor,
            requestFor:
              requestedFor && requestedFor._id ? requestedFor._id : null,
          });
          navigate("/find-help/services");*/
        },
      });
    }

    if (type === "helper" && showOnBoarding) {
      newNav.shift();
      newNav.unshift({
        icon: (
          <img
            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/icons/Welcome.svg`}
            alt="Welcome icon"
          />
        ),
        url: "/welcome",
        label: "Welcome",
        types: ["sysadmin", "helper"],
        showBadge: true,
        badgeCount: welcomeNav.filter((n) => !n.isComplete).length,
        children: welcomeNav.map((b) => {
          return { ...b, isDisabled: b.isComplete };
        }),
      });
    }

    newNav.push({
      icon: null,
      url: null,
      label: "Sign Out",
      types: [],
      showBadge: false,
      btn: true,
      onClick: () => {
        queryClient.invalidateQueries();
        signOut();
      },
    });

    setNav([...newNav]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <MobileNav id="mobile-nav">
        <Container>
          <Row>
            <Col>
              <Link className="brand" to="/dashboard">
                <img src={logo} alt="Manana" title="Manana" />
              </Link>
            </Col>
            <Col className="text-end">
              <Mobile
                user={user}
                signOut={() => {}}
                links={{ links: nav }}
                showLogo={false}
              />
            </Col>
          </Row>
        </Container>
      </MobileNav>
      <InternalPageContent
        id="internal-page-content"
        className={internalPageClass}
      >
        {showNav ? (
          <ContentNav id="content-nav">
            <div className="d-flex justify-content-between align-items-center">
              <Link to="/" className="brand">
                <img
                  src={logo}
                  title="manana"
                  alt="manana"
                  style={{ maxWidth: 155 }}
                />
              </Link>
              <div style={{ marginRight: "2rem" }}>
                <UserNotifications />
              </div>
            </div>
            {renderList({ notifications, type, list: nav })}
          </ContentNav>
        ) : null}
        <ContentBody id="content-body" maxWidth={noMaxWidth ? "100%" : "800px"}>
          {!showOnBoarding &&
          user.userType === "helper" &&
          user.helper.backgroundCheck.status !== "clear" ? (
            <Alert variant="info">
              <Row>
                <Col xs={1}>
                  <InfoRounded />
                </Col>
                <Col xs={11}>
                  <p style={{ margin: 0, fontSize: "1.25rem" }}>
                    Your background check is still being processed.{" "}
                    <Link to="/background-check/status">
                      Check the status of your background check here.
                    </Link>
                  </p>
                </Col>
              </Row>
            </Alert>
          ) : null}
          {children}
        </ContentBody>
      </InternalPageContent>
      <Footer />
      <JobRequestStartModal
        show={newBooking}
        toggle={() => setNewBooking(false)}
      />
    </>
  );
};

export default InternalLayout;
