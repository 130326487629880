/*
customer:job:accepted
customer:job:canceled
customer:job:complete
customer:job:expired
customer:job:reminder:2hours
customer:job:reminder:24hours
customer:job:reminder:36hours
customer:payment:failed:24hours
customer:payment:failed:36hours
customer:payment:refund
customer:payment:success
*/

export const CUSTOMER_OPTIONS = [
  {
    subTitle: "Login",
    children: [{ header: "Verification Code", key: "verification" }],
    noProfiles: true,
  },
  {
    subTitle: "Bookings",
    children: [
      { header: "Booking created", key: "customer:job:new" },
      { header: "Booking accepted", key: "customer:job:accepted" },
      { header: "Booking expired", key: "customer:job:expired" },
      { header: "Booking completed", key: "customer:job:complete" },
      {
        header: "Booking canceled",
        key: "customer:job:canceled",
        associated: [
          "customer:job:canceled:customer:lessthan24hours",
          "customer:job:canceled:customer:morethan24hours",
          "customer:job:canceled:helper:lessthan24hours",
          "customer:job:canceled:helper:morethan24hours",
        ],
      },
      { header: "36 hour reminder", key: "customer:job:reminder:36hours" },
      { header: "24 hour reminder", key: "customer:job:reminder:24hours" },
      { header: "2 hour reminder", key: "customer:job:reminder:2hours" },
    ],
  },
  {
    subTitle: "Payments",
    children: [
      {
        header: "Issue with payment warning",
        key: "customer:payment:failed:36hours",
      },
      {
        header: "Issue with payment canceled",
        key: "customer:payment:failed:24hours",
      },
      { header: "Payment completed", key: "customer:payment:success" },
      { header: "Refund issued", key: "customer:payment:refund" },
    ],
  },
  {
    subTitle: "Messages",
    children: [{ header: "New Message", key: "message:new" }],
  },
];

export const HELPER_OPTIONS = [
  {
    subTitle: "Login",
    children: [{ header: "Verification Code", key: "verification" }],
  },
  {
    subTitle: "Bookings",
    children: [
      { header: "New booking request", key: "helper:job:new" },
      { header: "Booking accepted", key: "helper:job:accepted" },
      { header: "Booking expired", key: "helper:job:expired" },
      { header: "Booking completed", key: "helper:job:complete" },
      {
        header: "Booking request accepted by other helper",
        key: "helper:job:accepted-other",
      },
      {
        header: "Booking canceled",
        key: "helper:job:canceled",
        associated: [
          "helper:job:canceled:helper:lessthan24hours",
          "helper:job:canceled:helper:morethan24hours",
          "helper:job:canceled:customer:morethan24hours",
          "helper:job:canceled:customer:lessthan24hours",
        ],
      },
      { header: "36 hour reminder", key: "helper:job:reminder:36hours" },
      { header: "24 hour reminder", key: "helper:job:reminder:24hours" },
      { header: "2 hour reminder", key: "helper:job:reminder:2hours" },
    ],
  },
  {
    subTitle: "Payments",
    children: [
      { header: "Issue with payment", key: "helper:payment:failed:24hours" },
      { header: "Bank transfer started", key: "helper:payment:payout" },
    ],
  },
  {
    subTitle: "Messages",
    children: [{ header: "New Message", key: "message:new" }],
  },
];

export const getInitialFormData = (user) => {
  const data = {};
  const existing = user[user.userType].notifications || {};
  const OPTIONS =
    user.userType === "helper" ? HELPER_OPTIONS : CUSTOMER_OPTIONS;

  OPTIONS.forEach((opts) => {
    opts.children.forEach((opt) => {
      if (user.userType === "helper") {
        data[opt.key] = existing[opt.key] || { sms: false, email: true };

        if (opt.associated) {
          opt.associated.forEach((o) => {
            data[o] = existing[o] || { sms: false, email: true };
          });
        }

        return;
      }

      user.customer.profiles.forEach((profile) => {
        const profileKey =
          profile.relationship === "_self" ? "_self" : profile._id;

        if (!data[profileKey]) {
          data[profileKey] = {};
        }

        data[profileKey][opt.key] = existing[profileKey][opt.key] || {
          sms: false,
          email: true,
        };

        if (opt.associated) {
          opt.associated.forEach((o) => {
            data[profileKey][o.key] = existing[profileKey][o] || {
              sms: false,
              email: true,
            };
          });
        }

        // we need ot make sure that _self is always available - we do
        // it here so that we don't have to loop more than once and in
        // this case we'll overwrite _self later if one is found
        if (!data._self) {
          data._self = {};
        }

        if (!data._self[opt.key]) {
          data._self[opt.key] = existing?._self[opt.key] || {
            sms: false,
            email: true,
          };

          if (opt.associated) {
            opt.associated.forEach((o) => {
              data._self[o.key] = existing?._self[o] || {
                sms: false,
                email: true,
              };
            });
          }
        }
      });
    });
  });

  // now we need to ensure that the _self key is always first
  const { _self, ...others } = data;

  return { _self, ...others };
};

export const findNotificationOptionByKey = (options, key) => {
  let opt = null;

  options.forEach((set) => {
    set.children.forEach((o) => {
      if (o.key === key) {
        opt = o;
      }
    });
  });

  return opt;
};
