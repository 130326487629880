import React, { useState } from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useQueryClient } from "react-query";
import moment from "moment-timezone";
import { toast } from "react-toastify";

import ServicesDisplayList from "./ServicesDisplayList";
import JobRequestEditForm from "./JobRequestEditForm";
import JobRequestCancelForm from "./JobRequestCancelForm";

const getProfile = (booking) => {
  if (!booking) {
    return { name: "Unknown" };
  }

  let profile = booking.customer.customer.profiles.find(
    (p) => p._id === booking.requestFor
  );

  if (!profile) {
    profile = booking.customer.customer.profiles[0];
  }

  return profile;
};

const JobDetailsModal = ({ show, toggle, job }) => {
  const [action, setAction] = useState("");
  const queryClient = useQueryClient();
  const profile = getProfile(job);

  const onHandleActionSelection = (e) => {
    setAction(e.target.value);
  };

  const renderActions = () => {
    if (
      job.canceledOn ||
      job.jobStatus === "Booking Expired" ||
      job.jobStatus === "Booking Canceled"
    ) {
      return <small>No actions are available.</small>;
    }

    return (
      <div className="mb-4">
        <div className="d-flex justify-content-start align-items-center gap-2">
          <label>Actions:</label>
          <Form.Select
            onChange={onHandleActionSelection}
            value={action}
            style={{ width: "auto" }}
          >
            <option value="">Select an action</option>
            <option value="edit">Edit Job Request</option>
            <option value="cancel">Cancel Job Request</option>
          </Form.Select>
        </div>
      </div>
    );
  };

  const renderActionForm = () => {
    switch (action) {
      case "edit":
        return (
          <JobRequestEditForm
            job={job}
            onSuccess={() => {
              toggle();
              setAction("");
              queryClient.invalidateQueries({ queryKey: ["admin-bookings"] });
              toast.success("The job request was successfully updated.");
            }}
            onCancel={() => setAction("")}
          />
        );

      case "cancel":
        return (
          <JobRequestCancelForm
            job={job}
            onSuccess={() => {
              toggle();
              setAction("");
              queryClient.invalidateQueries({ queryKey: ["admin-bookings"] });
              toast.success("The job request was successfully canceled.");
            }}
            onCancel={() => setAction("")}
          />
        );
      default:
        return null;
    }
  };

  const render = () => {
    if (!show || !job) {
      return null;
    }

    return (
      <>
        {job.jobStatus === "Booking Canceled" ? (
          <div className="alert alert-warning">
            <p className="mb-0">
              This job request was canceled on{" "}
              {moment(job.canceledOn).format("MM/DD/YYYY h:mm A")}.
            </p>
          </div>
        ) : null}
        {job.jobStatus === "Booking Expired" ? (
          <div className="alert alert-warning">
            <p className="mb-0">
              This job request expired on{" "}
              {moment(job.dates.startDateTime).format("MM/DD/YYYY h:mm A")}.
            </p>
          </div>
        ) : null}

        {job.event && job.event.error ? (
          <div className="alert alert-danger">
            <p>
              This booking encountered an error on{" "}
              {moment(job.event.error.errorDate).format("MM/DD/YYYY h:mm A")}.
            </p>
            <p className="mb-0">Error: {job.event.error.errorMsg}</p>
          </div>
        ) : null}
        {renderActions()}
        {renderActionForm()}
        <hr />
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Customer</Form.Label>
              <p>
                <Link to={`/admin/users/${job.customer._id}`}>
                  {job.customer.name}
                </Link>
              </p>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Requested Helpers</Form.Label>
              {job.requestedHelpers.map((h, idx) => {
                return (
                  <p>
                    <Link to={`/admin/users/${h._id}`}>{h.name}</Link>
                  </p>
                );
              })}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Date</Form.Label>
              <p>
                {moment(job.dates.startDateTime).format("MM/DD/YYYY h:mm A")} -{" "}
                {moment(job.dates.startDateTime)
                  .add(job.hours, "hours")
                  .format("h:mm A")}
              </p>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Request For</Form.Label>
              <p>{profile.name}</p>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <p>
                {profile.address.street1}, {profile.address.city},{" "}
                {profile.address.state} {profile.address.zip}
              </p>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Services</Form.Label>
              <ServicesDisplayList
                selectedServices={job.servicesRequested}
                specializedCare={job.specializedCare}
              />
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Modal show={show} onHide={() => toggle()} size="xl">
      <Modal.Body>{render()}</Modal.Body>
    </Modal>
  );
};

export default JobDetailsModal;
