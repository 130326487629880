import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useFormData } from "../../hooks/useFormData";
import { useApiGet } from "../../hooks/useApi";
import { useUser } from "../../contexts/UserContext";
import { useJob } from "../../contexts/JobContext";
import PreviousHelperSelect from "../common/PreviousHelperSelect";

/*
const requestedFor =
  user.customer?.profiles.length > 0
    ? user.customer.profiles[0]
    : null;
resetJob({
  isNewCustomer: false,
  serviceArea: { _id: user.customer.serviceArea },
  zip: user.address.postal,
  requestedFor: requestedFor,
  requestFor:
    requestedFor && requestedFor._id ? requestedFor._id : null,
});
navigate("/find-help/services");
*/

const JobRequestStartModal = ({ show, toggle }) => {
  const { user } = useUser();
  const { resetJob } = useJob();
  const { formData, onChange } = useFormData({ helper: null, options: [] });
  const navigate = useNavigate();

  const { isLoading, data } = useApiGet("previous-helpers", `/users/previous`);

  useEffect(() => {
    if (data) {
      const helpers = data.helpers.map((h) => {
        return {
          label: h.name,
          value: h._id,
          record: h,
        };
      });
      onChange({ target: { name: "options", value: helpers } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const startNewBooking = () => {
    resetJob({
      acceptedOn: null,
      canceledOn: null,
      createdOn: null,
      helper: formData.helper.value,
      customer: user._id,
      serviceArea: { _id: user.customer.serviceArea },
      zip: user.address.postal,
      services: data.services,
      requestedHelpers: [formData.helper.value],
      declinedBy: [],
      viewedBy: [],
      jobStatus: "New help request",
      isNewCustomer: false,
      vehicleRequired: data.vehicleRequired,
      specializedCare: data.specializedCare,
      availability: null,
      callBeforeRequired: data.callBeforeRequired,
      paymentMethod: data.paymentMethod,
      requestFor: data.profile,
      requestedFor: data.profile,
      hours: 3,
      jobType: "One-Time",
      helpers: [formData.helper.value],
      _helpers: [{ ...formData.helper.record }],
      _rebook: true,
    });
    navigate(`/find-help/helpers`);
  };

  const renderPrevious = () => {
    if (isLoading) {
      return (
        <>
          <p>Loading previous list...</p>
          <hr />
        </>
      );
    }

    if (data.helpers.length === 0) {
      return null;
    }

    return (
      <>
        <p className="lead body">Book a helper you've worked with before.</p>
        <Form.Group className="mb-3">
          <Form.Label>Previous Helpers</Form.Label>
          <PreviousHelperSelect
            options={formData.options}
            value={formData.helper}
            onChange={(val) =>
              onChange({ target: { name: "helper", value: val } })
            }
          />
        </Form.Group>
        <div className="mb-3">
          <Button
            variant="outline-secondary"
            disabled={!formData.helper}
            size="lg"
            className="w-100"
            onClick={() => startNewBooking()}
          >
            Book Helper Again
          </Button>
        </div>
        <hr />
      </>
    );
  };

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Header>
        <h2>New Booking</h2>
      </Modal.Header>
      <Modal.Body>
        {renderPrevious()}
        <Button
          variant="outline-secondary"
          className="w-100"
          size="lg"
          onClick={() => {
            const requestedFor =
              user.customer?.profiles.length > 0
                ? user.customer.profiles[0]
                : null;
            resetJob({
              isNewCustomer: false,
              serviceArea: { _id: user.customer.serviceArea },
              zip: user.address.postal,
              requestedFor: requestedFor,
              requestFor:
                requestedFor && requestedFor._id ? requestedFor._id : null,
            });
            navigate("/find-help/services");
          }}
        >
          Start A New Booking
        </Button>
      </Modal.Body>
    </Modal>
  );
};

JobRequestStartModal.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

export default JobRequestStartModal;
